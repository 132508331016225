import { render, staticRenderFns } from "./InputUploadPlaceholder.vue?vue&type=template&id=69b4f324&scoped=true&"
import script from "./InputUploadPlaceholder.vue?vue&type=script&lang=js&"
export * from "./InputUploadPlaceholder.vue?vue&type=script&lang=js&"
import style0 from "./InputUploadPlaceholder.vue?vue&type=style&index=0&id=69b4f324&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69b4f324",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('69b4f324')) {
      api.createRecord('69b4f324', component.options)
    } else {
      api.reload('69b4f324', component.options)
    }
    module.hot.accept("./InputUploadPlaceholder.vue?vue&type=template&id=69b4f324&scoped=true&", function () {
      api.rerender('69b4f324', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Input/InputUploadPlaceholder.vue"
export default component.exports