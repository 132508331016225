var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showIcon
    ? _c(
        "label",
        { class: _vm.classes },
        [
          _c("Icon", [_c("path", { attrs: { d: _vm.icon, stroke: "none" } })]),
          _c("span", { staticClass: "showIcon" }, [_vm._v(_vm._s(_vm.label))])
        ],
        1
      )
    : _c("label", { class: _vm.classes }, [
        _vm._v("\n    " + _vm._s(_vm.label))
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }